@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  padding-left: 15px;
}

a {
  text-decoration: none;
  color: #3f51b5;
}

img, svg {
  max-width: 100%;
}

body {
  font-family: Poppins, sans-serif;
}

.MuiButton-root .MuiCircularProgress-root {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.MuiButton-root .MuiSvgIcon-root {
  display: block;
}

.sketch-picker {
  box-shadow: none !important;
}

.fullHeightWidth {
  width: 100%;
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.draft-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  min-height: 200px;
}

.draft-wrapper .public-DraftStyleDefault-block {
  padding: 0 10px;
}

.draft-wrapper .public-DraftEditor-content ul,
.draft-wrapper .public-DraftEditor-content ol {
  padding-left: 10px;
}

.draft-wrapper .rdw-dropdown-optionwrapper {
  max-height: 150px;
  overflow: auto;
}

.header-account-button,
.header-account-button div {
  color: white;
  letter-spacing: 2px;
}

.big-add-button {
  height: 250px;
  width: 100%;
}

.media-image {
  height: 200px;
  position: relative;
  overflow: hidden;
}

.media-image img {
  height: 80%;
  object-fit: cover;
  border-radius: 6px;
}

.media-image .media-overlay {
  position: absolute;
  top: 200px;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .4s ease-in-out;
}

.media-image:hover .media-overlay {
  top: 0;
  z-index: 99;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
  min-width: 160px;
}

.show-more {
  color: #0056B1;
  cursor: pointer;
}

.list-item-text {
  color: #4A4B68 ;
}
.list-item-text > span  {
  font-weight: 600 !important;

}

.overflow-visible {
  overflow: visible !important;
}

.table-overflow {
  overflow-x: auto;
}

#image-dialog-title h2 {
  font-size: 20px;
}

.RoleMultiSelectorStyles > div > div {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent;
  padding: 10px 5px;
}

.RoleMultiSelectorStyles > div > div > div {
  height: 40px;
  overflow-y: auto;
}

.RoleMultiSelectorStyles > div > div + div {
  background-color: #fff;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  z-index: 23;
  margin: 0;
}

.RoleMultiSelectorStyles > div > div + div > div {
  height: auto !important;
}

.customHeaderBadge .MuiBadge-badge {
  background:#fff;
  color: #000;
}

.overflow-visible {
  overflow: initial !important;
}

.capitalize-text {
  text-transform: capitalize;
}

input[type="email"] {
  text-transform: lowercase;
}

.textTransform-capitalize {
  text-transform: capitalize;
}

.padding-left {
  padding-left: 0px;
}

.pointer-cursor {
  cursor: pointer;
}

.active {
  color: #009EF6;
}

.listing-header {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
}

.overflowY-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiPopover-paper {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05) !important;
  border-radius: 6px !important;
  border: 1px solid #E4E6EF;
}

.MuiPopover-paper.user-setting-menu {
  margin: -40px 0 0 18px;
  width: 240px;
  left: 40px !important;
}

.custom-calendar .MuiPickersStaticWrapper-staticWrapperRoot {
  margin-top: -10px;
}

.custom-calendar .MuiPickersBasePicker-pickerView {
  min-height: 330px;
  justify-content: flex-start;
}

.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-iconButton {
  padding: 5px;
}

.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiPickersSlideTransition-transitionContainer > p {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-daysHeader .MuiPickersCalendarHeader-dayLabel {
  font-size: 14px;
  width: 42px;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-day {
  height: 42px;
  width: 42px;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-daySelected {
  background-color: #009EF6;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-day .MuiIconButton-label > p {
  font-size: 15px;
  color: #666666;
  font-weight: 500;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-current .MuiIconButton-label > p {
  color: #009EF6;
}

.MuiPickersBasePicker-pickerView .MuiPickersSlideTransition-transitionContainer .MuiPickersDay-daySelected .MuiIconButton-label > p {
  color: #fff;
}

body .MuiTabPanel-root {
  padding: 20px 0 0 0;
}

.react-tel-input {
  font-family: "Poppins, sans-serif"; 
  font-size: 14px;
}

.react-tel-input .form-control {
  width: 100%;
  border-color: #ECF0F3;
  height: 42px;
  color: currentColor;
}

.react-tel-input .flag-dropdown {
  border-color: #ECF0F3;
}

.MuiCheckbox-colorPrimary, 
.MuiCheckbox-colorSecondary 
{
  width: 40px;
}

.MuiCheckbox-colorPrimary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root 
  {
  color: #204ECF !important;
  width: 40px;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label:before {
  display: none;
}

.subHeading {
  color: #B5B5C3;
  font-size: 20px;
  font-weight: 500;
  max-width: 532px;
  padding-bottom: 26px;
}

.billing-box {
  color: #009EF6;
  cursor: pointer;
}

.billing-box .MuiTypography-root {
  padding-left: 5px;
}

.outlinedButton {
  border: 2px solid #1DC894 !important;
  color: #ffffff !important;
  width: 260px;
  min-height: 70px;
  margin: 10px;
  animation: myEffectTwo 3000ms ease-in-out;
}

@keyframes myEffectTwo {
  0% {
    opacity: 0;
    transform: translateZ(200%);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

.outlinedButton .MuiButton-label {
  font-size: 18px;
}
